import Layout from '../components/layout/Layout';
import React from 'react';
import { Link } from 'gatsby';

const NotFound = () => (
  <Layout>
    <section className='pt-20 md:pt-40'>
      <div className='container mx-auto px-8 text-center'>
        <h1 className='text-4xl lg:text-6xl xl:text-7xl font-bold title-gradient text-gradient 
        !leading-normal'>Oops! Página no encontrada</h1>
        <p className='pt-4 text-gray-600'>Lo sentimos, pero la página que buscas no existe.</p>
        <p className='text-xl lg:text-2xl mt-6 font-light'>Quizás quieras volver a la página principal.</p>
        <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
          <button className='mt-8 cursor-pointer font-bold shadow-lg btn-gradiant' style={{ padding: '12px 24px', backgroundColor: '#007BFF', border: 'none', borderRadius: '5px', color: 'white' }}>
            Volver a la página principal
          </button>
        </Link>
      </div>
    </section>
  </Layout>
);

export default NotFound;
